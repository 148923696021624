import caller from "./Caller";

export const getBlogPosts = async (pageIndex) => {
  const { data } = await caller({
    method: "GET",
    url: "/BlogPosts/GetWithPagination",
    params: {
      requestPage: pageIndex,
    },
  });

  return data;
};

export const getPostBySlug = async (slug) => {
  const { data } = await caller({
    method: "GET",
    url: "/BlogPosts/GetBlogPostWithUrlSlug",
    params: {
      slug,
    },
  });

  return data;
};
